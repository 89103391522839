import Fade from '@rio-cloud/rio-uikit/Fade';
import { uniqueId } from 'lodash';
import { useNavigate } from 'react-router';
import { STOPS_ROUTE } from '../../common/constants';
import { useAppSelector } from '../../configuration/setup/hooks';
import { SearchEditor } from '../../features/search/SearchEditor';
import { selectShowStops } from '../../store/app/appSelectors';
import { ToggleRangeSupport } from './ToggleRangeSupport';

export const RouteStopoversPage = () => {
    const navigate = useNavigate();
    const showContent = useAppSelector(selectShowStops);

    return (
        <Fade
            animationStyle={Fade.PAGE}
            show={showContent}
            onExitComplete={() => {
                navigate(STOPS_ROUTE);
            }}
        >
            <div className="display-flex flex-column" data-testid="route-stopovers">
                <div className="padding-left-15 padding-right-15" style={{ marginTop: '30px' }}>
                    <div className="StopoversOptions display-flex flex-column gap-25" data-testid="stopovers-options">
                        <div key={uniqueId()} data-testid="show-range">
                            <ToggleRangeSupport />
                        </div>
                        <div key={uniqueId()} data-testid="search-editor">
                            <SearchEditor />
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    );
};

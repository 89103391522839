import { DeepPartial } from '@reduxjs/toolkit';
import { GetFeatureTogglesApiResponse } from '../../../src/store/facade/facadeApi';

const allowedFT = [true, false, undefined];

const getRandomFlagValue = () => allowedFT[Math.floor(Math.random() * allowedFT.length)];

export const generateFeatureTogglesMock = (): DeepPartial<GetFeatureTogglesApiResponse> => ({
    searchOptions: true,
    vehicleOptions: true,
    vehicleOutdoorTemperature: false,
    routeOptions: true,
    chargingOptions: true,
    newRoutingFeature: true,
});

import getSymbolFromCurrency from 'currency-symbol-map';
import { isUndefined } from 'lodash';
import first from 'lodash/fp/first';
import isEmpty from 'lodash/fp/isEmpty';
import last from 'lodash/fp/last';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';
import { Unit, WINDOW_BOUNDING_BOX_DEGREES } from '../../common/constants';
import { Coords } from '../../common/types';
import { convertMetersIntoKilometers, convertSecondsIntoHHmm } from '../../common/utils';
import { RootState, store } from '../../configuration/setup/store';
import { selectCurrentStep } from '../../store/app/appSelectors';
import { NavigationStep } from '../../store/app/appSlice';
import {
    AdditionalStopSection,
    ArrivalSection,
    ChargingStation,
    ChargingStationSection,
    DepartureSection,
    DrivingSection,
    RouteSection,
    SectionType,
} from '../../store/facade/facadeApi';
import { selectSelectedRoute, selectSuggestedRoutes, selectVehicleCosts } from '../../store/route/routeSelectors';
import { suggestedRoutesRemoved } from '../../store/route/routeSlice';
import { renderShapes } from '../../store/widget/actions/renderShapes';
import { sendMessage } from '../../store/widget/widgetServices';
import { widgetActions } from '../../store/widget/widgetSlice';
import { mapToCoords, Route, RouteSummary, TripDetails } from '../fetchData/mappers/mapRoutes';
import { WaypointWithCoordinates } from '../fetchData/createRouteParameters';

interface MarkerProps {
    active: boolean;
    iconNames: string[];
    markerColor: string;
    name?: string;
    clickable?: boolean;
    fixed?: boolean;
}

export interface Marker {
    id: string;
    position: Coords;
    markerProps: MarkerProps;
}

export interface Segment {
    points: Coords[];
    alternative: boolean;
}

const createWaypointMarker = (waypoint: WaypointWithCoordinates, icon: string): Marker => ({
    id: uuidv4(),
    position: waypoint.coordinates,
    markerProps: {
        active: false,
        iconNames: [icon],
        markerColor: 'bg-map-marker-route',
        clickable: false,
    },
});

const createRouteInfoMarker = (coordinates: Coords, isActive: boolean, info: string): Marker => {
    return {
        id: uuidv4(),
        position: coordinates,
        markerProps: {
            active: isActive,
            name: info,
            iconNames: ['route'],
            markerColor: 'bg-map-marker-route',
            clickable: false,
            fixed: true,
        },
    };
};

const getRouteInfoMarkerPosition = (coordinates: Coords[]): Coords => {
    return coordinates[Math.floor(coordinates.length / 2)];
};

export const createRouteInfo = (infoData: RouteSummary, state: RootState): string => {
    const routeDuration = convertSecondsIntoHHmm(infoData?.duration);
    const routeDistance = convertMetersIntoKilometers(infoData?.distance)?.toString();
    const routeCosts = infoData?.total_cost;
    const vehicleCosts = selectVehicleCosts(state);
    const routeCurrency = getSymbolFromCurrency(vehicleCosts.currency);

    return `${routeDuration} | ${routeDistance}${Unit.km} | ${routeCosts}${routeCurrency}`;
};

const createMarkersArray = (routes: Route[], selectedRoute: number, state: RootState): Marker[] => {
    const waypoints = routes[selectedRoute].waypoints;
    const chargingStops = routes[selectedRoute].chargingStops;
    const restingStops = routes[selectedRoute].restingStops;

    if (isEmpty(waypoints) && isEmpty(chargingStops) && isEmpty(restingStops)) {
        return [];
    }

    const firstMarker = createWaypointMarker(first(waypoints)!, 'start');
    const lastMarker = createWaypointMarker(last(waypoints)!, 'finish');

    const intermediaryWaypoints = waypoints.slice(1, waypoints.length - 1);
    const intermediaryMarkers = intermediaryWaypoints.map((waypoint) => createWaypointMarker(waypoint, 'arrow-down'));
    const charginStationMarkers = chargingStops.map((chargingStop) =>
        createWaypointMarker(chargingStop, 'filling-e-station')
    );
    const restingStationMarkers = restingStops.map((restingStop) =>
        createWaypointMarker(restingStop, 'status-resting')
    );

    const routeInfoMarkers = routes.map((route, index) => {
        const markerInfo = createRouteInfoMarker(
            getRouteInfoMarkerPosition(route.routeCoordinates),
            index === selectedRoute,
            createRouteInfo(route.summary, state)
        );

        return markerInfo;
    });

    return [
        firstMarker,
        lastMarker,
        ...intermediaryMarkers,
        ...charginStationMarkers,
        ...restingStationMarkers,
        ...routeInfoMarkers,
    ];
};

// ensure selected route is rendered atop alternatives in UiKit
const putSelectedRouteLast = (segments: Segment[], from: number): Segment[] => {
    const modifiableCopy = [...segments];
    const selectedRoute = modifiableCopy.splice(from, 1);
    return [...modifiableCopy, ...selectedRoute];
};

const createSegmentsArray = (routes: Route[], selectedRoute: number, state: RootState): Segment[] => {
    const isToShowAlternatives = selectCurrentStep(state) === NavigationStep.SummaryStep;
    const segments = routes.map((route, index) => ({
        points: route.routeCoordinates,
        alternative: isToShowAlternatives ? selectedRoute !== index : true,
        reduced: isToShowAlternatives ? selectedRoute !== index : true,
        showArrows: isToShowAlternatives ? selectedRoute === index : true,
    }));

    return putSelectedRouteLast(segments, selectedRoute);
};

const requestBoundingBox = (routes: Route[]) => {
    const waypointWithCoordinates: Coords[] = routes[0].waypoints.map((waypoint) => waypoint.coordinates);
    const routeCoordinates: Coords[] = routes.flatMap((route) => route.routeCoordinates);
    const coordinates = [...waypointWithCoordinates, ...routeCoordinates];

    const latitudes = coordinates.map((coordinate) => coordinate.lat);
    const longitudes = coordinates.map((coordinate) => coordinate.lng);

    sendMessage(
        widgetActions.setBoundingBox({
            bbox: {
                topLeft: {
                    lat: Math.max(...latitudes) + WINDOW_BOUNDING_BOX_DEGREES,
                    lng: Math.min(...longitudes) - WINDOW_BOUNDING_BOX_DEGREES,
                },
                bottomRight: {
                    lat: Math.min(...latitudes) - WINDOW_BOUNDING_BOX_DEGREES,
                    lng: Math.max(...longitudes) + WINDOW_BOUNDING_BOX_DEGREES,
                },
            },
        })
    );
};

export const propagateRoute = (dispatch: ThunkDispatch<Dispatch, RootState, any>, getState: () => RootState) => {
    const state = getState();

    const trip = selectSuggestedRoutes(state);
    const selectedRoute = selectSelectedRoute(state);

    if (!trip || isEmpty(trip)) {
        return;
    }

    const routes = getRoutesFromTrip(trip);

    const route = {
        segments: createSegmentsArray(routes, selectedRoute!!, state),
        markers: createMarkersArray(routes, selectedRoute!!, state),
    };

    sendMessage(widgetActions.renderRoute(route));
    // Temporarily remove rendering of stations along the route
    // const cs = getNearChargingStations(trip).map((station) =>
    //     new MapStationBuilder().withId(station?.id).withName(station?.name).withLocation(station?.location).build()
    // );
    // renderChargingStations(cs);
};

export const getNearChargingStations = (trip: TripDetails): ChargingStation[] => {
    const allNearChargingStations = trip.routes
        .flatMap((route) => route.sections)
        .filter((section) => section.sectionType === SectionType.Driving)
        .map((section) => section.info as DrivingSection)
        .flatMap((drivingSection) => drivingSection.nearChargingStations)
        .filter((station) => !isUndefined(station)) as ChargingStation[];
    return [...new Map(allNearChargingStations.map((station) => [station.id, station])).values()];
};

export const getRoutesFromTrip = (trip: TripDetails) =>
    trip.routes.map((route) => ({
        waypoints: getWaypointsFromRouteSections(route.sections),
        chargingStops: getChargingStopsFromRouteSections(route.sections),
        restingStops: getAditionalStopsFromRouteSections(route.sections),
        routeCoordinates: route.drivingCoordinates,
        summary: route.summary,
    }));

export const getWaypointsFromRouteSections = (sections: RouteSection[]) =>
    sections
        .filter(
            (section) => section.sectionType === SectionType.Arrival || section.sectionType === SectionType.Departure
        )
        .map((section) => section.info as ArrivalSection | DepartureSection)
        .map((section, idx) => {
            const result = {
                id: idx,
                address: section.address,
                coordinates: mapToCoords(section.location),
            };
            return result;
        });

export const getChargingStopsFromRouteSections = (sections: RouteSection[]) =>
    sections
        .filter((section) => section.sectionType === SectionType.ChargingStation)
        .map((section) => section.info as ChargingStationSection)
        .map((section, idx) => {
            const result = {
                id: idx,
                sectionType: SectionType.ChargingStation,
                address: section.charging_station.address,
                coordinates: mapToCoords(section.charging_station.location),
            };
            return result;
        });

export const getAditionalStopsFromRouteSections = (sections: RouteSection[]) =>
    sections
        .filter((section) => section.sectionType === SectionType.AdditionalStop)
        .map((section) => section.info as AdditionalStopSection)
        .map((section, idx) => {
            const result = {
                id: idx,
                sectionType: SectionType.AdditionalStop,
                address: section.address,
                coordinates: mapToCoords(section.location),
            };
            return result;
        });

export const clearRouteAndMarkers = () => {
    const route = {
        segments: [],
        markers: [],
    };

    sendMessage(widgetActions.renderRoute(route));
    renderShapes();

    store.dispatch(suggestedRoutesRemoved());
};

export const propagateBoundingBox = (dispatch: ThunkDispatch<Dispatch, RootState, any>, getState: () => RootState) => {
    const state = getState();
    const trip = selectSuggestedRoutes(state);

    if (!trip || isEmpty(trip)) {
        return;
    }

    requestBoundingBox(getRoutesFromTrip(trip));
};

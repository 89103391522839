import getSymbolFromCurrency from 'currency-symbol-map';
import { first, last } from 'lodash/fp';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { convertISOToRelativeDate, convertMetersIntoKilometers, convertSecondsIntoHHmm } from '../../common/utils';
import { DefaultPanel } from '../../components/Panel/DefaultPanel';
import { useAppSelector } from '../../configuration/setup/hooks';
import { trace } from '../../configuration/setup/trace';
import { resolveAddress } from '../../features/fetchData/resolveAddress';
import { ArrivalSection, DepartureSection, RouteResponse, SectionType } from '../../store/facade/facadeApi';
import { supportedLocaleMap } from '../../store/lang/lang';
import { selectLocale } from '../../store/lang/langSelectors';
import { selectVehicleCosts } from '../../store/route/routeSelectors';
import { selectOrderedWaypoints } from '../../store/search/searchSelectors';
import { WaypointType } from '../../store/search/searchSlice';
import { TourSummaryPanelBody } from './TourSummaryPanelBody';
import { TourSummaryPanelFooter } from './TourSummaryPanelFooter';
import { TourSummaryPanelHeader } from './TourSummaryPanelHeader';

export type TourSummaryProps = {
    trip: RouteResponse;
    selectedRoute: number;
};

export const TourSummary = ({ trip, selectedRoute }: TourSummaryProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isFirstWpVehicleLocation, setIsFirstWpVehicleLocation] = useState<boolean>();
    const [vehicleAddress, setVehicleAddress] = useState<string>();

    const userLocale = useAppSelector(selectLocale);
    const vehicleCosts = useAppSelector(selectVehicleCosts);
    const waypoints = useAppSelector(selectOrderedWaypoints);
    const departureWaypoint = first(waypoints);
    const arrivalWaypoint = last(waypoints);

    const localLang = (Object.keys(supportedLocaleMap) as (keyof typeof supportedLocaleMap)[]).find((key) => {
        return key !== userLocale && supportedLocaleMap[key] === userLocale;
    });

    const tourTimeStamp = trip?.timestamp;
    const tourRoutes = trip?.routes[selectedRoute];
    const routeSummary = tourRoutes?.summary;
    const routeSections = tourRoutes?.sections;

    const routeDepartureSection = routeSections?.find((section) => section?.sectionType === SectionType.Departure)
        ?.info as DepartureSection;
    const tourDepartureTime = routeDepartureSection?.departure_time;

    const routeArrivalSection = routeSections?.find((section) => section?.sectionType === SectionType.Arrival)
        ?.info as ArrivalSection;
    const tourArrivalTime = routeArrivalSection?.estimated_arrival_time;

    const getTourSummaryPanelHeader = () => {
        return (
            <TourSummaryPanelHeader
                headerDuration={convertSecondsIntoHHmm(routeSummary?.duration)}
                headerDistance={convertMetersIntoKilometers(routeSummary?.distance)?.toString()}
            />
        );
    };

    const getTourSummaryPanelBody = () => {
        return (
            <TourSummaryPanelBody
                bodyDepTime={convertISOToRelativeDate(localLang, tourDepartureTime)}
                bodyDepAddress={isFirstWpVehicleLocation ? vehicleAddress : departureWaypoint?.address}
                bodyArrTime={convertISOToRelativeDate(localLang, tourArrivalTime)}
                bodyArrAddress={arrivalWaypoint?.address}
                totalConsumption={routeSummary?.total_consumption}
                co2Savings={routeSummary?.co2_savings}
                vehicleCost={routeSummary?.vehicle_cost}
                energyCost={routeSummary?.energy_cost}
                tollCost={routeSummary?.toll_cost}
                totalCost={routeSummary?.total_cost}
                currency={getSymbolFromCurrency(vehicleCosts.currency)}
            />
        );
    };

    const getTourSummaryPanelFooter = () => {
        return <TourSummaryPanelFooter footerTimestamp={convertISOToRelativeDate(localLang, tourTimeStamp)} />;
    };

    const getVehicleLocation = async () => {
        try {
            const eventObj = {
                type: 'EVENT_SMART_ROUTING',
                payload: {
                    coordinates: departureWaypoint?.coordinates,
                },
            };

            const resolvedAddress = await dispatch(resolveAddress(eventObj));
            setVehicleAddress(resolvedAddress);
        } catch (error) {
            trace.error('Could not resolve vehicle address: ', error);
        }
    };

    useEffect(() => {
        if (
            departureWaypoint &&
            (departureWaypoint.type === WaypointType.VEHICLE_LOCATION ||
                departureWaypoint.address === intl.formatMessage({ id: 'intl-msg:smartRoute.tour.useVehicleLocation' }))
        ) {
            setIsFirstWpVehicleLocation(true);
            getVehicleLocation();
        } else {
            setIsFirstWpVehicleLocation(false);
        }
    });

    return (
        <div data-testid="tour-summary-panel">
            <div className="text-size-18 text-medium margin-bottom-10" data-testid="tour-alternatives-label">
                <FormattedMessage id="intl-msg:smartRoute.tour.summary" />
            </div>
            <DefaultPanel
                panelHeader={getTourSummaryPanelHeader()}
                panelBody={getTourSummaryPanelBody()}
                panelFooter={getTourSummaryPanelFooter()}
            />
        </div>
    );
};
